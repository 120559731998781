import React from 'react';
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import style from './style.module.scss';

export function WorkedWith(){
    return (
        <div id={style.container}>
            <h2>Some of the people we have worked with...</h2>

            <div id={style.logosWrap}>
                <Img fixed={getWWImage("UCL", false)} className={style.img} alt="University college London engineering logo" />
                <Img fixed={getWWImage("WTP", false)} className={style.img} alt="WT Partnership logo" />
                <Img fixed={getWWImage("AER", false)} className={style.img + " " + style.aer} alt="Advanced engine research logo" />
            </div>
            <div id={style.logosWrapSmall}>
                <Img fluid={getWWImage("UCL", true)} className={style.img} alt="University college London engineering logo" />
                <Img fluid={getWWImage("WTP", true)} className={style.img} alt="WT Partnership logo" />
                <Img fluid={getWWImage("AER", true)} className={style.img + " " + style.aer} alt="Advanced engine research logo" />
            </div>
        </div>
    );
}

function getWWImage(fileName, fluid) {
    let data = useStaticQuery(graphql`
        query wwImages {
            allFile(filter: {relativePath: {glob: "components/WorkedWith/**"}}) {
                nodes {
                    childImageSharp {
                        fixed(height: 80) {
                            ...GatsbyImageSharpFixed_withWebp
                            originalName
                        }
                        fluid(maxHeight: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                            originalName
                        }
                    }
                }
            }
        }
    `).allFile.nodes;

    if (fluid){
        for (let i = 0; i < data.length; i++) {
            if (data[i].childImageSharp.fluid.originalName.split('.')[0] === fileName){
                return data[i].childImageSharp.fluid;
            }
        }

    }else{
        for (let i = 0; i < data.length; i++) {
            if (data[i].childImageSharp.fixed.originalName.split('.')[0] === fileName){
                return data[i].childImageSharp.fixed;
            }
        }
    }

    throw new Error('Image not found');
}